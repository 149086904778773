<div id="providerlist-container" class="provider-search-container reveal-parent floating-div-container">

  <div class="wrapper">

    <section id="providerlist">
      <div class="floating-row" id="fixed-row">
        <div class="small-12 columns" style="max-width:100% !important" *ngIf="printUserName()">
          <div class="text-right">
            <span>Hi, {{loggedInName}}</span> |
            <a class="login-button" (click)="logout()">Log Out</a>
          </div>
        </div>
        <div class="row ">
          <div class="columns">
            <h1 class="pull-left">Provider List</h1>




            <app-export [style.display]="isGenerateReportVisible ? 'inline' : 'none'"></app-export>




            <a [style.display]="isViewProviderListVisible ? 'inline' : 'none'"
              class="button madium black pull-right mt-20" (click)="navigateToProviderList()">View Provider List
              ({{providerCount}})</a>




          </div>
        </div>
      </div>
      <div class="row">
        <div class="columns mt-150">


          <p style="clear:both">WBQuote’s Provider Search allows you to determine the contract status for doctors,
            hospitals and medical groups
            requested by your client or prospect and use that information to select the carriers, packages and plans
            that
            will work best to meet their needs. </p>


        </div>
      </div>

    </section>
  </div>
</div>
<ngx-spinner></ngx-spinner>
<!-- <ng4-loading-spinner> </ng4-loading-spinner> -->
<div [@animRoutes]="getPage(appOutlet)">
  <router-outlet #appOutlet="outlet"></router-outlet>
</div>