import { AppSettings } from '../shared/appSettings';

import { AuthConfig } from 'angular-oauth2-oidc';

export class LocalStorage {
    public static Name: string = "name";
    public static Email_Id: string = "email_id";
    public static Access_Token: string = "access_token";
    public static State: string = "state";
    public static Nonce: string = "nonce";
    public static Exp_Date: string = "exp_date";
    public static UserName: string = "username";
    public static SalesRep: string = "salesRep";
    public static Id_Token: string = "id_token";
    public static Token_Type: string = "token_type";
    public static Exp_In: string = "exp_in";
    public static GroupId: string = "group_id";
    public static TenantId: string = "tenant";
    public static IsEmbeddedApp: string = "isEmbeddedApp";
    public static IsBlueShieldProviderIncluded: string = "IsBlueShieldProviderIncluded";
    public static Role: string = "role";
}

export class ProviderSearchMethod {
    public static Geolocations: string = 'geolocations';
    public static ProviderList: string = 'providerlist';
    public static DeleteProviderbyId: string = 'DeleteProviderList';
    public static ProviderName: string = 'ProviderNames';
    public static AddProvider: string = 'PostProviderList';
    public static UpdateIncludeBlueShield: string = 'UpdateIncludeBlueShieldProvider'
    public static EmailPDF: string = 'email';
    public static GroupedByProvider: string = 'GroupedByProvider';
}

export class Settings {
    public static MinimumAutoPopulateCharacters: number = 3;
}

export class Tenant {
    public static Id: string = 'b3c62278-6f97-4377-a04a-1bc3244d699c';
    public static Name: string = 'ProviderSearchWeb';
    public static Header: string = 'Tenant';
}
export class staticString {
    public static ProviderListAvailable = " WBQuote's Provider Search allows you to determine the contract status for doctors, hospitals and medical groups requested" +
        "by your client or prospect and use that information to select the carriers, packages and plans that will work" +
        "best to meet their needs";
    public static ProviderListNotAvailable = "No providers have been added for this group. Would you like to add some now?";

}
export class ProvidersSupportedCarriers {
    public static SupportedCarriers = [
        { id: 1, name: 'Aetna', selected: false },
        { id: 3, name: 'Anthem Blue Cross', selected: false },
        { id: 8, name: 'Blue Shield', selected: false },
        { id: 7, name: 'CaliforniaChoice', selected: false },
        { id: 9, name: 'Health Net', selected: false },
        { id: 22, name: 'Oscar', selected: false },
        { id: 13, name: 'Sharp', selected: false },
        { id: 2, name: 'UnitedHealthcare', selected: false }


    ];
}

export const authConfig: AuthConfig = {

    // issuer of the Identity Provider
    issuer: AppSettings.getEnvironmentVariable('authEndPoint'),

    // Url of the Identity Provider
    loginUrl: AppSettings.getEnvironmentVariable('authEndPoint') + 'connect/authorize',

    // URL of the SPA to redirect the user to after login
    redirectUri: AppSettings.getEnvironmentVariable('redirectUri'),

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: 'provider_search_web',

    // set the scope for the permissions the client should request
    scope: 'openid profile email read write ProviderSearch_API',

    oidc: true,
    /**
   * Defines whether https is required.
   * The default value is remoteOnly which only allows
   * http for localhost, while every other domains need
   * to be used with https.
   */
  requireHttps: !!AppSettings.getEnvironmentVariable('AuthRequireHttps'),

  /**
   * If true, the lib will try to check whether the user
   * is still logged in on a regular basis as described
   * in http://openid.net/specs/openid-connect-session-1_0.html#ChangeNotification
   */
  sessionChecksEnabled: !!AppSettings.getEnvironmentVariable(
    'AuthSessionChecksEnabled',
  ),
}