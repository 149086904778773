import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ZelisApiService } from '../service/zelisApi.service';
import { fadeInAnimation } from '../animations/fade-in.animation';
@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],
 
  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class ProviderComponent implements OnInit {

  constructor(private _routes: Router, private _zelisApi: ZelisApiService) { }
  providerUniqueId: string = "";
  masterCensusGroupId:string="";
  ngOnInit() {
    this._zelisApi.toggleGenerateReport(false);
    this._zelisApi.toggleViewProviderList(false);
  }
  displayProviderList() {
   
localStorage.setItem("masterCensusId",  this.masterCensusGroupId) 
localStorage.setItem("providerListUUId", this.providerUniqueId) 

    this._routes.navigate(['addProvider']);
  }

}
