import { Injectable, OnInit } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { RoleType } from '../model/enums/role.enum';

@Injectable()
export class ApplicationInsightsService {
  private routerSubscription: Subscription;
  private _properties: { [name: string]: string } = {};
  private _turnedOn: boolean;

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.ApplicationInsightsInstrumentationKey,
      disableTelemetry:
        environment.DisableApplicationInsights === 'false' ? false : true,
      disableFetchTracking: false,
      disableCorrelationHeaders: false,
      enableCorsCorrelation: true,
    },
  });

  constructor(private router: Router) {
    this._turnedOn = false;
  }

  turnOn() {
    if (!this._turnedOn) {
      this._turnedOn = true;
      this.appInsights.loadAppInsights();

      const telemetryInitializer = envelope => {
        envelope.tags['ai.cloud.role'] = 'Provider-Search.UI';
      };
      this.appInsights.addTelemetryInitializer(telemetryInitializer);

      this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(
            event.state.root,
          );
          if (activatedComponent) {
            const route = this.getRouteTemplate(event.state.root);
            const eventAfterRedirects = event.urlAfterRedirects.toString();
            this.logPageView(`${route}`, eventAfterRedirects);
          }
        });
    }
  }

  turnOff() {
    this._turnedOn = false;
    this.appInsights.addTelemetryInitializer(() => false);
    this.routerSubscription.unsubscribe();
  }

  setUserId(userId: string, userName: string, userRole: RoleType) {
    this._properties['Email'] = userId;
    this._properties['Name'] = userName;
    this._properties['Role'] = RoleType[userRole];

    this.appInsights.setAuthenticatedUserContext(userId);
  }

  setQuoteId(quoteId: string) {
    this._properties['QuoteId'] = quoteId;
  }

  clearUserId() {
    // delete this._properties['Email'];
    // this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(
    name?: string,
    uri?: string,
    additionalProperties?: { [name: string]: string },
  ) {
    const properties: { [name: string]: string } = {
      ...this._properties,
      ...additionalProperties,
    };
    this.appInsights.trackPageView({ name, uri, properties });
  }

  trackEvent(name?: string, additionalProperties?: { [name: string]: string }) {
    const properties: { [name: string]: string } = {
      ...this._properties,
      ...additionalProperties,
    };
    this.appInsights.trackEvent({ name, properties });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
