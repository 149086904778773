import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, RouterModule, Routes, Router } from "@angular/router";
import { LocalStorage } from "../shared/constants";
import { ZelisApiService } from '../service/zelisApi.service';
import { fadeInAnimation } from '../animations/fade-in.animation';
import { ThrowStmt } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }

})
export class AddProviderComponent implements OnInit {
  providerListUUId: string = "";
  masterCensusId: string = ""
  isdisclaimerVisible: string = "";
  visibleaddProvider: boolean;
  visibledisclaimer: boolean;
  constructor(
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute, private _routes: Router, private _zelisApi: ZelisApiService) {


  }

  navigateToDisclaimer() {
    this._zelisApi.toggleGenerateReport(false);

    if (this.isdisclaimerVisible == "True") {
      this._routes.navigate(['providerSearch']);
    }
    else {
      if (localStorage.getItem(LocalStorage.IsEmbeddedApp) == "true") {
        localStorage.setItem("isdisclaimerVisible", "True");
      }

      this.visibledisclaimer = false;
      this.visibleaddProvider = true;
    }

  }
  ngOnInit() {
    this.visibleaddProvider = false;
    this.visibledisclaimer = true;
    this.masterCensusId = localStorage.getItem("masterCensusId");
    this.providerListUUId = localStorage.getItem("providerListUUId");
    this.isdisclaimerVisible = localStorage.getItem("isdisclaimerVisible");
    this._zelisApi.toggleGenerateReport(true);
    this._zelisApi.toggleViewProviderList(false);
  }
  navigateToProviderSearch() {

    this._routes.navigate(['providerSearch']);

  }
  navigateToAddProvider() {
    this._zelisApi.toggleGenerateReport(false);
    this.visibledisclaimer = true;
    this.visibleaddProvider = false;
  }


}
