import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileService } from '../service/file.service';
import { saveAs, FileSaver } from 'file-saver/FileSaver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ZelisApiService } from '../service/zelisApi.service';
import { LocalStorage } from '../shared/constants';
declare var $: any;

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {


  emailAddresses: string = "";
  email = "[A-Za-z0-9\._%-]+@[A-Za-z0-9\.-]+\.[A-Za-z]{2,4}";
  showEmailSection = false;
  multipleEmailPattern = new RegExp('^' + this.email + '(;\\n*' + this.email + ')*;?$');
  providerListUUId = "";
  defaultEmail = "";
  masterCensusId: any;
  emailError = false;
  showEmailMessage = false;
  brokerName = "";
  userName = "";
  salesRep = "";
  constructor(private _service: FileService,
    private loader: NgxSpinnerService,
    private _zelisService: ZelisApiService) {

    this.masterCensusId = Number(localStorage.getItem("masterCensusId"));
    this.providerListUUId = localStorage.getItem("providerListUUId");
    this.defaultEmail = localStorage.getItem("email_id");
    this.emailAddresses = this.defaultEmail;
  }
  subscription: any;
  ngOnInit() {
    this.subscription = this._zelisService.getCountChangeEmitter()
      .subscribe(item => this.showHideExportButton(item));

  }
  enableEmailSection() {
    this.emailAddresses = localStorage.getItem("email_id");
    this.showEmailSection = true;
    this.showEmailMessage = false;
  }
  initializeExport() {
    this.showEmailSection = false;
    this.showEmailMessage = false;
    this.emailAddresses = localStorage.getItem("email_id");

  }
  downloadPDF() {

    this.providerListUUId = localStorage.getItem("providerListUUId");
    this.showEmailSection = false;
    this._service.downloadPDF(this.providerListUUId, this.masterCensusId);
    this.loader.show();

    this._service.downloadPDF(this.providerListUUId, this.masterCensusId).subscribe(data => {

      this.downloadFile(data.body); this.loader.hide()
    }),
      error => console.log("Error downloading the file."),
      () => console.info("");
  }
  downloadFile(data: any) {
    var fileName = 'providerList.pdf';

    var blob = new Blob([data as BlobPart], { type: 'application/pdf' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }
    var fileURL = URL.createObjectURL(blob);
    window.open(fileURL);

    saveAs(blob, fileName);
    this.showEmailMessage = false;

  }
  enableExportButton = false;
  showHideExportButton(count: number) {
    if (count > 0) {

      this.enableExportButton = true;
    }
    else {
      this.enableExportButton = false;
    }

  }
  emailPDF(enteredEmail) {
    this.showEmailMessage = false;
    this.providerListUUId = localStorage.getItem("providerListUUId");
    var addresses = enteredEmail == "" ? this.defaultEmail : enteredEmail;
    this.brokerName = $("#hdnBrokerValidUserName").val();
    this.defaultEmail = localStorage.getItem(LocalStorage.Email_Id);
    this.userName = $("#hdnBrokerName").val();
    this.salesRep = $("#hdnBrokerValidSalesRep").val();
    this.loader.show();
    this._zelisService.emailPDF(addresses, this.masterCensusId, this.providerListUUId, this.brokerName, this.defaultEmail, this.userName, this.salesRep).subscribe(data => {
      var flag = Boolean(data);
      this.loader.hide();
      if (flag == true) { this.showEmailMessage = true; this.emailError = false; }
      else { this.showEmailMessage = true; this.emailError = true; }
    }
    ),
      error => { this.loader.hide(); this.showEmailMessage = true; this.emailError = true; },
      () => console.info("OK");

  }

}
