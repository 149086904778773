// Angular Dependencies
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

//provider Search Components
import { AddProviderComponent } from './add-provider/add-provider.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ProviderlistComponent } from './providerlist/providerlist.component';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { ManualEntryComponent } from './provider-search/manual-entry/manual-entry.component';
import { ImportSearchComponent } from './provider-search/import/import-search/import-search.component';
import { ImportComponent } from './provider-search/import/import.component';
import { ManualEntrySearchComponent } from './provider-search/manual-entry/manual-entry-search/manual-entry-search.component';
import { SearchResultComponent } from './provider-search/manual-entry/search-result/search-result.component';
import { ImportSearchResultComponent } from './provider-search/import/import-search-result/import-search-result.component';
import { ExportComponent } from './export/export.component';


//Provider Search Services
import { HttpClientService } from './service/httpClient.service';
import { ZelisApiService } from './service/zelisApi.service';
import { HttpErrorHandler } from './service/httpErrorHandler.service';
import { MessageService } from './service/message.service';
import { ProviderlistService } from './providerlist/providerlist.service';
import { ManualEntryService } from './provider-search/manual-entry/manual-entry.service';
import { AuthGuard } from './shared/auth.guard'
import { AuthService } from './shared/auth.service'



//Custom Pipes
import { CheckNull, ConcatStringArray } from './shared/customPipes';

//Third party libraries
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from './service/file.service';
import { APP_BASE_HREF } from '@angular/common';
import { ProviderComponent } from './provider/provider.component';// for autocomplete
import { OAuthModule } from 'angular-oauth2-oidc';


//App Insights
import { ApplicationInsightsService } from './service/application-insights.service';


const appRoutes: Routes = [
  {
    path: '',
    component: ProviderComponent, canActivate: [AuthGuard]
  },
  {
    path: 'provider',
    component: ProviderComponent, canActivate: [AuthGuard]
  },
  {
    path: 'addProvider',
    component: AddProviderComponent, canActivate: [AuthGuard]
  },

  {
    path: 'providerlist',
    component: ProviderlistComponent, canActivate: [AuthGuard]
  },

  {
    path: 'providerSearch',
    component: ProviderSearchComponent, canActivate: [AuthGuard]
  }
];


@NgModule({
  declarations: [
    AppComponent,
    ProviderlistComponent,
    AddProviderComponent,
    DisclaimerComponent,
    ProviderSearchComponent,
    ManualEntryComponent,
    ImportComponent,
    ManualEntrySearchComponent,
    SearchResultComponent,
    ImportSearchResultComponent,
    ImportSearchComponent,
    ExportComponent,
    CheckNull,
    ConcatStringArray,
    ProviderComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    OAuthModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    FormsModule, BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgbModule

  ],
  providers: [AuthGuard, AuthService, HttpClientService, ZelisApiService, ProviderlistService, ManualEntryService,
    HttpErrorHandler, MessageService,
    FileService, { provide: APP_BASE_HREF, useValue: '/' }, ApplicationInsightsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
