import { Injectable, EventEmitter, AfterViewChecked } from '@angular/core';
import { HttpClientService } from '../service/httpClient.service';
import { HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ProviderList, GeoLocation } from '../model/providerlist.model';
import { HttpErrorHandler, HandleError } from './httpErrorHandler.service';
import { catchError } from 'rxjs/operators';
import { AppSettings } from '../shared/appSettings';
import { ProviderSearchMethod } from '../shared/constants';
import { Tenant } from '../shared/constants';
import { AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Tenant': Tenant.Name + '/' + Tenant.Id
    })
};


@Injectable()
export class ZelisApiService {
    newProviderCount: Subject<number> = new Subject<number>();
    private handleError: HandleError;
    viewProviderListToggled: Subject<boolean>;
    private baseUrl = `${AppSettings.getEnvironmentVariable('apiEndpoint')}ProviderSearch/`;
    private utilityBaseUrl = `${AppSettings.getEnvironmentVariable('apiEndpoint')}utility/`;
    constructor(private _httpClientService: HttpClientService, _httpErrorHandler: HttpErrorHandler, private cdr: ChangeDetectorRef) {
        this.handleError = _httpErrorHandler.createHandleError('ZelisApiService');
        this.viewProviderListToggled = new Subject<boolean>();
    }

    getProviderList(providerUId: string) {
        let url = `${this.baseUrl}${ProviderSearchMethod.ProviderList}/${providerUId}`;
        return this._httpClientService.get<ProviderList>(url, httpOptions)
            .pipe(
                catchError(this.handleError('getProviderList', []))
            );
    }

    getGeoLocations(searchValue: string, isPreferred: boolean = false) {
        let url = `${this.baseUrl}${ProviderSearchMethod.Geolocations}/${searchValue}/${isPreferred}`;
        return this._httpClientService.get<GeoLocation[]>(url, httpOptions)
            .pipe(
                catchError(this.handleError('getGeoLocations', []))
            );
    }
    deleteProviderbyId(providerLocationList) {
        const httpDeleteOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Tenant': Tenant.Name + '/' + Tenant.Id
            }),
            body: JSON.stringify(providerLocationList)
        };
        let url = `${this.baseUrl}${ProviderSearchMethod.DeleteProviderbyId}`;
        return this._httpClientService.delete<boolean>(url, httpDeleteOptions)
            .pipe(
                catchError(this.handleError('deleteProviderbyId', []))
            );
    }
    addProviderbyId(providerLocationList) {
        let url = `${this.baseUrl}${ProviderSearchMethod.AddProvider}`;
        return this._httpClientService.post<String>(url, JSON.stringify(providerLocationList), httpOptions)
            .pipe(
                catchError(this.handleError('addProviderbyId', []))
            );
    }
    updateIncludeBlueShield(masterCensusId, includeBlueShield) {
        debugger;
        let url = `${this.baseUrl}${ProviderSearchMethod.UpdateIncludeBlueShield}`;
        let data = { "MasterCensusGroupId": masterCensusId, "IsBlueShieldProviderIncluded": includeBlueShield }
        return this._httpClientService.post(url, data, httpOptions)
            .pipe(
                catchError(this.handleError('updateIncludeBlueShield', []))
            );
    }
    emailPDF(emailTo, MasterGroupId, providerUId, brokerName, brokerEmail, username, salesRep) {
        var model = {
            "EmailTo": emailTo,
            "MasterGroupId": MasterGroupId,
            "ProviderListUUID": providerUId,
            "BrokerName": brokerName,
            "BrokerEmail": brokerEmail,
            "UserName": username,
            "SalesRep": salesRep
        };
        let url = `${this.utilityBaseUrl}${ProviderSearchMethod.EmailPDF}`;
        return this._httpClientService.post<boolean>(url, JSON.stringify(model), httpOptions)
            .pipe(
                catchError(this.handleError('addProviderbyId', []))
            );
    }
    countChanged: EventEmitter<number> = new EventEmitter<number>();
    count: number = 0;
    counterChanged(cnt: number) {
        this.count = cnt;
        this.newProviderCount.next(cnt);
        this.countChanged.emit(cnt);
        return cnt;
    }
    getCountChangeEmitter() {
        return this.countChanged;
    }
    generateReportToggled: Subject<boolean> = new Subject<boolean>();
    generateReportVisibilityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    toggleGenerateReport(flag: boolean) {

        this.generateReportToggled.next(flag);

    }

    toggleViewProviderList(flag: boolean) {
        this.viewProviderListToggled.next(flag);
        this.cdr.detectChanges();
    }

}