import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ProviderList, GeoLocation } from '../model/providerlist.model';
import { ZelisApiService } from '../service/zelisApi.service';
import { staticString } from '../shared/constants';
import { fadeInAnimation } from '../animations/fade-in.animation';

@Component({
  selector: 'app-providerlist',
  templateUrl: './providerlist.component.html',
  styleUrls: ['./providerlist.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class ProviderlistComponent implements OnInit {
  _providerList: ProviderList[];
  initialProviderList: any;
  providerlistUUid: string;
  masterCensusId: string;
  isproviderListavailable: string = "";
  providerCount: number = 0;
  constructor(private loader: NgxSpinnerService, private route: ActivatedRoute, private _zelisApiService: ZelisApiService) {

  }
  addLocationError = false;
  removeLocationError = false;

  ngOnInit() {
    this.masterCensusId = localStorage.getItem("masterCensusId");
    this.providerlistUUid = localStorage.getItem("providerListUUId");
    this._zelisApiService.toggleViewProviderList(false);
    if (this.providerlistUUid != '' && this.providerlistUUid != null && this.providerlistUUid != undefined) {

      this.getProviderList(this.providerlistUUid);
    }
    else {
      this.isproviderListavailable = staticString.ProviderListNotAvailable;
    }
  }

  getProviderList(providerListUUId) {
    this.loader.show();
    this._providerList = [];
    this._zelisApiService.getProviderList(providerListUUId).subscribe((data: any) => {
      if (data.length > 0) {
        this._providerList = <any>data;

        this.isproviderListavailable = "";
        this.countCurrentProviders(data);
        this._zelisApiService.counterChanged(this.providerCount)
      }
      else {
        this.isproviderListavailable = staticString.ProviderListNotAvailable;
      }
      this.loader.hide();
    });
  }
  countCurrentProviders(data) {

    if (data != undefined) {
      data.forEach(currentProvider => {
        if (currentProvider.ProviderLocations !== undefined && currentProvider.ProviderLocations.length !== 0) {
          currentProvider.ProviderLocations.forEach(currentLocation => {
            this.providerCount = this.providerCount + 1;

          })
        }

      });
    }
  }
  removeProvider(providerLocation) {

    this.removeLocationError = false;
    this.loader.show();
    let deleteProvider = {
      ProviderListUUID: this.providerlistUUid,
      ProviderLocationId: Number(providerLocation.ProviderLocationId),
      NetworkIds: providerLocation.NetworkIds
    }
    this._zelisApiService.deleteProviderbyId(deleteProvider).subscribe(data => {
      if (data != []) {
        providerLocation.showAdd = true;
        this.removeLocationError = false;
        this.providerCount = this.providerCount - 1;
        this._zelisApiService.counterChanged(this.providerCount);

        if (this.providerCount <= 0) { // set blue shield checkbox to false if all providers are removed
          this._zelisApiService.updateIncludeBlueShield(this.masterCensusId, false).subscribe((data: any) => {
            localStorage.setItem("IsBlueShieldProviderIncluded", "false");
          });
        }
        this.loader.hide();
      }
      else {
        this.removeLocationError = true;
        this.loader.hide();
      }
    });
  }

  addProvider(providerLocation) {
    this.addLocationError = false;
    this.loader.show();
    let deleteProvider = {
      MasterCensusGroupId: Number(this.masterCensusId),
      ProviderListUUID: this.providerlistUUid,
      ProviderLocationId: Number(providerLocation.ProviderLocationId),
      NetworkIds: providerLocation.NetworkIds
    }
    this._zelisApiService.addProviderbyId(deleteProvider).subscribe(
      data => {
        if (data) {
          providerLocation.showAdd = false;
          this.addLocationError = false;
          this.providerCount = this.providerCount + 1;
          this._zelisApiService.counterChanged(this.providerCount);
          this.loader.hide();
        }
        else {
          this.loader.hide();
          this.addLocationError = true;
        }
      });
  }
}