
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AppSettings } from '../shared/appSettings';
import { Tenant } from '../shared/constants';

const headers = new HttpHeaders({
    'Tenant': Tenant.Name + '/' + Tenant.Id
});


@Injectable()
export class FileService {
    _baseURL: string = `${AppSettings.getEnvironmentVariable('apiEndpoint')}utility/`
    constructor(private http: HttpClient) { }
    upload(files) {

        return this.http.post(this._baseURL + 'import', files, { headers, responseType: 'json', observe: 'response' }).pipe(

            catchError(error => observableThrowError(error)));

    }
    download() {

        return this.http.get(this._baseURL + 'Download', { responseType: 'blob', observe: 'response', headers: headers }).pipe(
            map((response: HttpResponse<Blob>) => response),
            catchError(error => observableThrowError(error)));

    }
    downloadPDF(uuID, masterGroupId) {
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/pdf');


        return this.http.get(this._baseURL + 'pdf' + '?providerListUUID=' + uuID + '&masterGroupId=' + masterGroupId, { responseType: 'blob', observe: 'response', headers: headers }).pipe(
            map((response: HttpResponse<Blob>) => response),
            catchError(error => observableThrowError(error)));

    }
}