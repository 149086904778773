
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, RouterModule, Routes, Router } from "@angular/router";
import { ZelisApiService } from '../service/zelisApi.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
  providerlistUUid: string;
  masterCensusId: string;

  constructor(
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute, private _routes: Router, private _zelisApi: ZelisApiService) {
    this.masterCensusId = localStorage.getItem("masterCensusId");
    this.providerlistUUid = localStorage.getItem("providerListUUId");

  }

  navigateToProviderSearch() {

    this._routes.navigate(['providerSearch']);
  }
  navigateToAddProvider() {

    this._routes.navigate(['addProvider']);
  }
  ngOnInit() {


  }

}
