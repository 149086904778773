<div>
  <div id="providerlist-container" class="provider-search-container">

    <div class="wrapper">

      <section id="disclaimer">
        <div id="providerlist-container" class="provider-search-container">

          <div class="wrapper">
            <!--Search Box-->

            <app-manual-entry-search [providerListData]="providerList"></app-manual-entry-search>

            <!--Search Result-->
            <app-search-result></app-search-result>
          </div>
        </div>




      </section>

    </div>

  </div>

</div>