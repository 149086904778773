<div *ngIf="!visibleaddProvider" [@fadeInAnimation]>

  <div id="providerlist-container" class="provider-search-container">

    <div class="wrapper">

      <section id="providerlist">


        <div class="row">
          <div class="columns">
            <div class="buttons toggle">
              <a class="button large" (click)="navigateToDisclaimer()">Add Provider</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <app-providerlist></app-providerlist>
</div>




<div id="providerlist-container" class="provider-search-container" [@fadeInAnimation] *ngIf="!visibledisclaimer">

  <div class="wrapper">

    <!-- Disclaimer Should be here -->
    <section id="disclaimer">

      <div class="row">
        <div class="columns">
          <h1>PROVIDER SEARCH - TERMS AND CONDITIONS</h1>
        </div>
      </div>

      <div class="row">
        <div class="columns">
          <p>
            The information contained herein, including without limitation, provider search results, was collected by Us
            from sources considered accurate and reliable at the time of collection. However, We have not verified, nor
            can We guarantee the accuracy, timeliness, or completeness of such information. Users of this information
            further acknowledge and agree that the information contained below is provided merely as a guide by Us. This
            information is not a substitute for health care advice, and We do not recommend a particular provider of
            health care or health care service. It is, therefore, recommended that the user verify the status of your
            coverage and the below information with your doctor's office and the carrier before relying upon and basing
            any decisions on the information provided. Provider network composition is subject to change. The
            information contained within the provider search results is provided on an “AS-IS” basis solely for the
            personal, non-commercial use of the respective users. We expressly disclaim all representations, warranties
            and liability related thereto.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="columns">
          <div class="buttons">
            <a (click)="navigateToAddProvider()" class="button large grey">Cancel</a>
            <a (click)="navigateToProviderSearch()" id="displaydisclaimer" class="button large">Accept</a>
          </div>
        </div>
      </div>

    </section>
  </div>

</div>