<div id="providerlist-container" class="provider-search-container">

  <div class="wrapper">

    <section id="providerlist">
      <div class="row">
        <div class="columns">
          <h1>Provider Search</h1>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="large-8 columns">
            <label>Master Census GroupId
              <input id="masterCensusGroupID" [(ngModel)]="masterCensusGroupId" name="masterCensusGroupID"
                #mCGroupID="ngModel" type="text" class="validate ps-input" placeholder="Enter Master Census Group Id"
                pattern="[0-9]*">
            </label>
          </div>
          <div class="large-8 columns" style="margin-left: 1%;">
            <label>Provider Unique Id
              <input id="providerUnique" [(ngModel)]="providerUniqueId" name="providerUniqueId" #providerId="ngModel"
                type="text" class="validate ps-input" placeholder="Enter Provider unique Id" required>
            </label>
          </div>

        </div>
      </form>
      <div class="row">
        <div class="columns">
          <div class="buttons toggle">

            <a class="button large" (click)="displayProviderList()">submit</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>