import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '../../service/file.service';
@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  errorMessage: string;
  @Input() providerList: any;
  public providerListData:any;
  @Input() count: number;
 
  @Output() countChanged: EventEmitter<number> =   new EventEmitter();
 constructor(private fileService: FileService) { }
 
  ngOnInit(){ 

this.providerListData = this.providerList;
console.log("child Data" + this.providerListData);
   }
  
  uploadNotification(status){
    if (status == true){
      
    }
}

}
