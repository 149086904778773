<div id="providerlist-container"   class="provider-search-container">

    <div class="wrapper">

      <section id="disclaimer">
        <div id="providerlist-container" class="provider-search-container">

          <div class="wrapper">

 <app-import-search 
      maxFiles="1"
      maxSize="2"
      fileExt="xlsx,xls"
      (uploadStatus)="uploadNotification($event)" [providerListData]="providerListData" >
    </app-import-search>



<app-import-search-result></app-import-search-result>

</div>
</div>
  


    
  </section>

</div>

</div>


