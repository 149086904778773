import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class HttpClientService {

    // Injecting the http client into the service
    constructor(private _httpClient: HttpClient) { }

    get<T>(url: string, header: any) {
        return this._httpClient.get<T>(url, header);
    }
    delete<T>(url: string, header: any) {
        return this._httpClient.delete(url, header)
    }
    post<T>(url: string, data: any, header: any) {
        return this._httpClient.post(url, data, header)
    }
}