export const environment = {
  production: ("__productionBool__").toLowerCase() == 'true' ? true : false,
  apiEndpoint: '__apiEndpoint__',
  authEndPoint: '__authEndPoint__',
  redirectUri: '__redirectUri__',
  ApplicationInsightsInstrumentationKey: '__ApplicationInsightsInstrumentationKey__',
  DisableApplicationInsights: '__DisableApplicationInsights__',
  AuthRequireHttps: '__AuthRequireHttps__',
  SingleSignoutRedirect: '__SingleSignoutRedirect__',
  AuthSessionChecksEnabled: '__AuthSessionChecksEnabled__',
};
