import { Injectable } from '@angular/core';
import { ZelisApiService } from '../service/zelisApi.service';
import { ProviderList, GeoLocation, ZelisApiError } from '../model/providerlist.model'
import { Observable } from 'rxjs';

@Injectable()
export class ProviderlistService {

    constructor(private _zelisApiService: ZelisApiService) { }
      
}
