<div class="row importTitleRow">
  <div class="small-24 medium-12 columns ">


    <h2>
      Import Provider list from Excel.
    </h2>

  </div>
  <div class="small-24 medium-12 columns text-right">

    <a class="button info right" (click)="download()">
      Download Excel Template
    </a>


  </div>
  <p> Some of your provider entries match multiple options on our side. We will walk you through each of those and let you select
    the one you were trying to enter.</p>
</div>
<div class="row">
  <div class="columns">

    <div class="row">
      <div class="small-24 medium-24 columns button-manual">
        <div draggable="true" ngClass="{{dragAreaClass}}" class="dragarea">

          <a href="javascript:void(0)" (click)="file.click()">
            Click to browse
          </a>
          Or Drag & Drop to upload your files
          <input #excelInput type="file" #file [multiple]="(maxFiles > 1)" (change)="onFileChange($event)" style="display:none" />
        </div>


      </div>
    </div>
    <div class="row ">
      <div class="medium-24 columns">

        <h3>Import Instructions</h3>

        1. Download our preformatted Excel Provider Template if you have not done so already
        <br /> 2. Open the empty preformatted Excel Template; enter and save your Group’s Providers
        <br /> 3. You can either drag and drop the file or click on drag and drop section to browse the file
        <br /> 4. File type should be Excel (.xlsx or xls) only
        <br /> 5. Click “Import” button to import your Group Providers”
        <br />
        <strong>“2 MB Maximum File Size”</strong>


      </div>
      <div class="import-error-list" *ngIf="errors.length!==0">
        <h3>Following errors have occured while importing file</h3>
        <ul>
          <li *ngFor="let errorMessage of errors">{{errorMessage}}</li>
        </ul>
      </div>      
      <div class="import-error-list" *ngIf="isResultVisible && searchResult.length !==0 && searchResult[0].ErrorMessage !== '' && searchResult[0].ErrorMessage !== null && searchResult[0].ErrorMessage !== undefined">
          <h3>Following errors have occured while importing file</h3>
          <ul>
              <li>Error (Invalid Template): {{searchResult[0].FileName}}</li>
          </ul>
        </div>
    </div>

  </div>


</div>



<span *ngIf="isResultVisible && searchResult.length!==0 && (searchResult[0].ErrorMessage == '' || searchResult[0].ErrorMessage == null || searchResult[0].ErrorMessage == undefined)">     
  <ngb-carousel #myDrop (click)="onSlideClicked(currSlide)">

    <ng-template ngbSlide *ngFor="let r of searchResult[0].ExcelResponse">


      <div class="row">
        <div class="columns">

          <div class="wrapper-entry">

            <div class="row align-center">
              <div class="small-24 medium-24 columns">

                <div class="provider-entry" class="text-left">
                  <h3>You Entered</h3>
                  <h2>{{r.ExcelRowData.ProviderName}}</h2>
                  <p> {{r.ExcelRowData.CityState}} {{r.ExcelRowData.Zip}}</p>
                  <br/>
                  <h3>We Found</h3>
                </div>

              </div>
            </div>
            <div *ngIf="r.ExcelRowData.ErrorMessages.length !== 0" class="import-search-error">
              <span>Import failed due to missing information</span>
              <br>
              <span *ngFor="let errorMessage of r.ExcelRowData.ErrorMessages">
                {{errorMessage}}
              </span>
            </div>
            <div *ngIf="r.ExcelRowData.ErrorMessages.length === 0 && r.SearchResult.Providers.length === 0" class="import-search-error">
              <span>No match was found</span>
            </div>
            <div *ngIf="r.ExcelRowData.ErrorMessages.length === 0">

              <div class="row pane" *ngFor="let p of r.SearchResult.Providers">
                <div class="columns">

                  <div class="row">
                    <div class="small-24 medium-18 large-20 columns">
                      <div class="colpad" *ngIf="p.FirstName!==null">
                        <h2>{{p.LastName}} {{p.FirstName}}</h2>
                      </div>
                      <div class="colpad" *ngIf="p.FirstName == null">
                        <h2>{{p.FacilityName}}</h2>
                      </div>
                    </div>

                  </div>

                  <div class="row provider-location" *ngFor="let l of p.Locations">
                    <div class="small-24 medium-12 large-10 columns">
                      <div class="row">
                        <div class="small-24 columns">
                          <div class="colpad">
                            <h3>{{l.Address}}</h3>
                            <p>{{l.City}},{{l.State}} {{l.Zip}}</p>
                          </div>
                        </div>
                        <div class="small-24 columns">
                          <div class="colpad">
                            <p class="text-blue">
                              <i>{{l.Specialities || concatStringArray}}</i>
                            </p>

                            <!-- <span class="accepting" *ngIf="l.AcceptingNewPatients">Accepting</span>
                                <span class="accepting not-accepting" *ngIf="!l.AcceptingNewPatients">Not Accepting</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="small-24 medium-9 large-10 columns">
                      <div class="colpad">
                        <div *ngFor="let c of l.CarrierGroupNames">
                            <h3 *ngIf="c.CarrierId===7">California<i style="font-weight: 400 !important;">Choice</i>
                            </h3>
                            <h3 *ngIf="c.CarrierId!==7">{{c.CarrierName}}</h3>                        
                          <p>{{c.GroupNames || concatStringArray}}</p>
                        </div>

                      </div>
                    </div>
                    <div class="small-24 medium-6 large-4 columns">
                      <div class="colpad">
                        <a class="button expanded" *ngIf="l.showAdd" (click)="addProvider(p,l)">Add</a>
                        <a class="button expanded grey" *ngIf="!l.showAdd" (click)="removeProvider(p,l)">Remove</a>
                      </div>
                    </div>
                  </div>


                </div>

              </div>
            </div>


          </div>

        </div>
      </div>
      <div class="carousel-caption">
        <!-- <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> -->
      </div>
      <div>

      </div>
    </ng-template>

  </ngb-carousel>
  <div class="row" style="margin-top: 2rem;">
    <div class="columns ">
      <a class="button madium pull-right" (click)="$event.stopPropagation(); myDrop.next(); onNextProviderClicked();">Review Next Provider</a>
    </div>
  </div>

</span>