import { Component, OnInit } from '@angular/core';
import { ManualEntryService } from '../manual-entry.service';
@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css'],
  providers: [ManualEntryService]
})
export class SearchResultComponent implements OnInit {
  isResultVisible: boolean;
  gbpResult: any;

  ngOnInit() {
    this, this.isResultVisible = false;
    this._service.gbpResultData.subscribe(value => alert(""));
  }

  constructor(private _service: ManualEntryService) { }


}
