import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ManualEntryService } from './manual-entry.service';
import { fadeInAnimation } from '../../animations/fade-in.animation';

declare var $: any;

@Component({
  selector: 'app-manual-entry',
  templateUrl: './manual-entry.component.html',
  styleUrls: ['./manual-entry.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }

})
export class ManualEntryComponent implements OnInit, AfterViewInit {

  ngAfterViewInit() {
  }

  isGBPVisible: boolean;

  @Input() providerListForManualEntry;
  public providerList: any;
  constructor(private _service: ManualEntryService) { }

  ngOnInit() {
    this.isGBPVisible = false;
    this.providerList = this.providerListForManualEntry;
    //console.log("child Data Manual Entry " +  this.providerListForManualEntry);
  }
  ColorChangedHandler(color: boolean) {
    this.isGBPVisible = color;
  }
}
