import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'checknull' })
export class CheckNull implements PipeTransform {
    transform(value: string): any {
        return value || '';
    }
}

@Pipe({ name: 'concatStringArray' })
export class ConcatStringArray implements PipeTransform {
    transform(value: string[]): any {
        return value.map(o => o).join(', ');
    }
}