<div id="providerlist-container" [@fadeInAnimation]  class="provider-search-container">
  <div class="wrapper">
    <section id="providerlist">
      <div class="row">
        <div class="columns">
          <p>
            {{isproviderListavailable}}
          </p>

        </div>
      </div>

      <div class="row pane" *ngFor="let _provider of _providerList">
        <div class="columns">

          <div class="row">
            <div class="small-24 medium-18 large-20 columns">
              <div class="colpad">
                <h2 *ngIf="_provider?.FacilityName==null;else  displayFacilityName">
                  {{_provider?.FirstName+' '+_provider?.LastName}}
                </h2>
                <ng-template #displayFacilityName>
                  <h2> {{_provider?.FacilityName}}</h2>
                </ng-template>
              </div>
            </div>           
          </div>

          <div class="row provider-location" *ngFor="let _plocation of _provider?.ProviderLocations">
            <div class="small-24 medium-expand columns shrink">
              <div class="row">
              <div class="colpad">
                  <h3>{{_plocation?.Address | checknull }} {{_plocation?.Address2 | checknull }} {{_plocation?.Address3 | checknull}}</h3>
                  <p>{{_plocation?.City | checknull}}, {{_plocation?.State | checknull}} {{_plocation?.Zip | checknull}}</p>
              </div>
              </div>
            </div>  
            <div class="small-24 medium-expand columns">
                <div class="colpad">                 
                  
                </div>
            </div>         
            <div class="small-24 medium-6 large-4 columns">
              <div class="colpad">                
									<a  class="button expanded" *ngIf="_plocation?.showAdd" (click)="addProvider(_plocation)">Add</a>
									<a class="button expanded grey" *ngIf="!_plocation?.showAdd" (click)="removeProvider(_plocation)">Remove</a>
									<div class="invalid-feedback" *ngIf="addLocationError">An error has occured while saving location.</div>
									<div class="invalid-feedback" *ngIf="removeLocationError">An error has occured while removing location.</div>						
              </div>
            </div>
          </div>
        
        </div>
      </div>


     
    </section>
  </div>

</div>
<div style="padding: 100px !important;">
  
  </div>