import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorage } from '../shared/constants';
import { AuthService } from '../shared/auth.service';
import { ApplicationInsightsService } from './../service/application-insights.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(

    private router: Router,
    private _authService: AuthService,
    private _applicationInsightsService: ApplicationInsightsService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (localStorage.getItem(LocalStorage.IsEmbeddedApp) != 'true') {
      if (!this._authService.isLoggedIn()) {
        this._authService.AuthenticateClientAndGetToken();
        return false;
      }
      if (this._authService.isLoggedIn()) return true;

      return false;
    } else {
      this._applicationInsightsService.turnOn();
    }
    return true;
  }
}
