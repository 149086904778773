
import { interval as observableInterval, Observable, of } from 'rxjs';

import { catchError, merge, switchMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, Injectable, EventEmitter, Output, ViewChild, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes, Router } from "@angular/router";
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ZelisApiService } from '../../../service/zelisApi.service';
import { ManualEntryService } from '../manual-entry.service';
import { GeoLocation } from '../../../model/providerlist.model';
import { ProvidersSupportedCarriers, Settings } from "../../../shared/constants";








import { fadeInAnimation } from './../../../animations/fade-in.animation';

declare var $: any;


const WIKI_URL = 'https://en.wikipedia.org/w/api.php';

const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});
let requestedString = "";

@Injectable()


@Component({
  selector: 'app-manual-entry-search',
  templateUrl: './manual-entry-search.component.html',
  styleUrls: ['./manual-entry-search.component.css'],
  providers: [ManualEntryService, NgbTypeaheadConfig],
  styles: [`.form-control { width: 300px; display: inline; }`]
})

export class ManualEntrySearchComponent implements OnInit, AfterViewInit {
  @ViewChild('providerSearch', { static: true }) vc;

  ngAfterViewInit() {
    var input_focus = function () {
      $('input.placeholder').unbind('focusin');
      $('input.placeholder').unbind('focusout');
      $('input.placeholder').bind('focusin', function () {

        var curr_input = $(this);
        var va = curr_input.attr('placeholder');
        curr_input.before('<p>' + va + '</p>');
        curr_input.prev('p').hide().fadeIn(500);
        curr_input.attr('placeholder', '');
      });
      $('input.placeholder').bind('focusout', function () {
        var curr_input = $(this);
        var curr_label = curr_input.prev('p');
        curr_input.attr('placeholder', curr_label.text());
        curr_input.prev('p').fadeOut(300, function () {
          curr_label.remove();
        });
        if (curr_input.val() != 0) {
          curr_input.addClass('done');
        }
        else {
          curr_input.removeClass('done');
        }
      });
    }

    input_focus();
  }
  isGBPVisible: boolean;
  gbpResultData: any;
  carriers: any;
  currentProvider: any;
  selectedAll: any;
  checkBlueShield: any;
  providerTypes: any;
  doctorSelected: boolean;
  facilitySelected: boolean;
  providerSearchMinLength: boolean;
  selectedCarriers = [];
  providerCount: number = 0;
  carrierString: string = "";
  currentProviderlist: any;
  providerlistUUid: string;
  masterCensusId: string;
  @Input() providerListData: any;
  countCurrentProviders() {
    if (this.currentProviderlist != undefined) {
      this.currentProviderlist.forEach(currentProvider => {
        if (currentProvider.ProviderLocations !== undefined && currentProvider.ProviderLocations.length !== 0) {
          currentProvider.ProviderLocations.forEach(currentLocation => {
            this.providerCount = this.providerCount + 1;

          })
        }

      });
    }
  }
  selectAll() {
    for (var i = 0; i < this.carriers.length; i++) {
      this.carriers[i].selected = this.selectedAll;
      this.displaySelectProvider = this.selectedAll ? true : false;
    }
    this.selectedCarriers = this.carriers.filter(
      carrier => carrier.selected === true);
    this.carrierString = this.selectedCarriers.map(o => o.id).join(',');
    //For Future Reference
    // this.carrierString = this.carrierString+",0";
  }
  checkIfAllSelected() {

    this.selectedAll = this.carriers.every(function (item: any) {
      return item.selected == true;
    });
    this.selectedCarriers = this.carriers.filter(
      carrier => carrier.selected === true);
    this.carrierString = this.selectedCarriers.map(o => o.id).join(',');
    for (var i = 0; i < this.carriers.length; i++) {
      if (this.carriers[i].selected == true) {
        this.displaySelectProvider = true;
        return;
      }
      else {
        this.displaySelectProvider = false;
      }
    }



  }

  providerTypeClick(selectedProvierType) {
    var otherProvider = selectedProvierType.id == 1 ? this.providerTypes[1] : this.providerTypes[0];
    if (selectedProvierType.selected) {
      return;
    }
    else {
      this.isGBPVisible = false;
      this.providerSearchFailed = false;
      this.providerSearchMinLength = false;
      this.searchingProvider = false;
      this.gbpError = false;
      this.providerModel = null;
      selectedProvierType.selected = true;
      otherProvider.selected = false;
      this.currentProvider = selectedProvierType;
    }
  }

  ngOnInit() {
    this.checkBlueShield = false;
    this.isGBPVisible = false;
    this.doctorSelected = true;
    this.facilitySelected = false;
    this.providerSearchMinLength = false;
    this.currentProviderlist = this.providerListData;
    this.countCurrentProviders();
    this._service.gbpResultData.subscribe(value => { value != "Error" ? (this.gbpResultData = value, this.isGBPVisible = true, this.loader.hide(), this.compareWithExisting(), this.gbpError = false, this.hideDropDown()) : (this.gbpError = true, this.loader.hide(), this.hideDropDown(), this.stopSearching(), setTimeout(() => { this.unsubscribeSearch(); }, 2000), this.searchProvider(of(""))) });

  }

  @Input() count: number;
  @Output() countChanged: EventEmitter<number> = new EventEmitter();
  displaySelectCarriers = false;
  displaySelectProvider = false;

  addLocationError = false;
  removeLocationError = false;
  gbpError = false;
  searchstring = "";
  prviderSearchString = "";
  model: any;
  providerModel: any;
  searching = false;
  sub: any;
  enableSearchSub: any;
  searchFailed = false;
  searchingProvider = false;
  providerSearchFailed = false;
  lastQueriedCarrierString = "";
  hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
  hideProviderSearchingWhenUnsubscribed = new Observable(() => () => this.searchingProvider = false);

  displayCarriers(model): void {
    this.displaySelectCarriers = this.model == "" ? false : true;
    if (this.model != "") {
      window.setTimeout(function () {
        window.scrollTo({
          top: $('#selectCarrier').offset().top,
          behavior: "smooth"
        });
      }, 500);
    }
  }
  getGBPData(model, eventType): void {
    if (eventType === 1) { model.preventDefault(); }
    if (this.providerModel != undefined && this.providerModel.length < 2) {
      this.providerSearchMinLength = true;
    }
    else if (this.providerModel != undefined) {
      this.providerSearchMinLength = false;
      var providerName = "";
      if (eventType === 1) {
        // this._service.search("").unsubscribe();
        providerName = eventType === 1 && typeof this.providerModel === 'string' ? this.providerModel : this.currentProvider.name == "Doctor" ? this.providerModel.FirstName + " " + this.providerModel.LastName : this.providerModel.FacilityName;

        this.stopSearching();
      }
      else {
        providerName = this.currentProvider.name == "Doctor" ? model.item.FirstName + " " + model.item.LastName : model.item.FacilityName;

      }

      this.loader.show();
      if(this.model.City!==undefined && this.model.City!=='')
      {
        //Search by city
      this._service.getGBPResult(this.model.City, this.carrierString, this.currentProvider.name, providerName, eventType === 1 ? "" : model.item.ProviderIds[0]);
      }
      else
      {
        //search by zipcode
      this._service.getGBPResult(this.model.Zip, this.carrierString, this.currentProvider.name, providerName, eventType === 1 ? "" : model.item.ProviderIds[0]);
      }
    }
  }



  constructor(private _service: ManualEntryService, private loader: NgxSpinnerService, private _zelisApiService: ZelisApiService, private route: ActivatedRoute, private _routes: Router, config: NgbTypeaheadConfig) {
    this.carriers = ProvidersSupportedCarriers.SupportedCarriers;

    for (var i = 0; i < this.carriers.length; i++) {
      this.carriers[i].selected = false;
    }

    this.providerTypes = [
      { id: 1, name: 'Doctor', placeHolder: "Doctor Name", selected: true },
      { id: 2, name: 'Facility', placeHolder: "Hospital or Urgent Care Facility", selected: false },

    ];
    this.currentProvider = this.providerTypes[0];
    this.masterCensusId = localStorage.getItem("masterCensusId");
    this.providerlistUUid = localStorage.getItem("providerListUUId");
    var isBlueShieldProviderIncluded = localStorage.getItem("IsBlueShieldProviderIncluded");
    this.checkBlueShield = isBlueShieldProviderIncluded == "true" ? true : false;
  }
  compareWithExisting() {

    if (this.gbpResultData.Providers !== undefined && this.gbpResultData.Providers.length !== 0) {
      this.gbpResultData.Providers.forEach(provider => {
        provider.showAdd = true;
        if (provider.Locations !== undefined && provider.Locations.length !== 0) {
          provider.Locations.forEach(location => {
            location.showAdd = true;

          })
        }
        if (this.currentProviderlist != undefined) {
          this.currentProviderlist.forEach(currentProvider => {
            if (provider.ProviderId == currentProvider.ProviderId) {
              if (provider.Locations !== undefined && provider.Locations.length !== 0) {
                provider.Locations.forEach(location => {
                  location.showAdd = true;
                  if (currentProvider.ProviderLocations !== undefined && currentProvider.ProviderLocations.length !== 0) {
                    currentProvider.ProviderLocations.forEach(currentLocation => {
                      if (location.ProviderLocationId == currentLocation.ProviderLocationId) {

                        location.showAdd = false;
                      }

                    })
                  }
                })
              }
            }
          })
        }
        else {
          return false;
        }
      })
    }
    return false;
  }

  stopSearching() {

    //this.sub = Observable.interval(100)
    //  .subscribe((val) => { this.hideDropDown(); });
  }

  enableSearching() {
    this.enableSearchSub = observableInterval(2000)
      .subscribe((val) => { this.unsubscribeSearch() });
  }
  unsubscribeSearch() {
    if (this.sub !== undefined) { this.sub.unsubscribe() }

  }
  removeProvider(provider, location) {

    this.removeLocationError = false;
    this.loader.show();

    let deleteProvider = {
      MasterCensusGroupId: Number(this.masterCensusId),
      ProviderListUUID: this.providerlistUUid,
      ProviderLocationId: Number(location.ProviderLocationId)

    }
    this._zelisApiService.deleteProviderbyId(deleteProvider).subscribe(data => {
      if (data) {
        location.showAdd = true;
        this.removeLocationError = false;
        this.loader.hide();
        this.providerCount = this.providerCount - 1;
        // US#381070 - Make sure ProviderCount is NEVER less than Zero
        if (this.providerCount < 0) {
          this.providerCount = 0;
        }
        this._zelisApiService.counterChanged(this.providerCount);
        this.displayProviderList();
      }
      else {
        this.removeLocationError = true;
        this.loader.hide();
      }
    });
  }

  updateIncludeBlueShield(): void {
    this.loader.show();
    this._zelisApiService.updateIncludeBlueShield(this.masterCensusId, this.checkBlueShield).subscribe((data: any) => {
      this.loader.hide();
      localStorage.setItem("IsBlueShieldProviderIncluded", this.checkBlueShield);
    });
  }
  addProvider(provider, location) {
    this.addLocationError = false;
    this.loader.show();
    let addProvider = {
      MasterCensusGroupId: Number(this.masterCensusId),
      ProviderListUUID: this.providerlistUUid,
      ProviderLocationId: Number(location.ProviderLocationId),
      IsBlueShieldProviderIncluded: this.checkBlueShield,
      ProviderId: Number(provider.ProviderId),
      SelectedCarrierIds: location.CarrierGroupNames.map(o => o.CarrierId).join(','),
      NetworkIds: location.Networkids
    }
    this._zelisApiService.addProviderbyId(addProvider).subscribe((data: any) => {
      if (data !== "") {
        location.showAdd = false;
        this.addLocationError = false;
        this.providerlistUUid = data;
        localStorage.setItem("providerListUUId", data);
        this.providerCount = this.providerCount + 1;
        this._zelisApiService.counterChanged(this.providerCount);
        this.loader.hide();
        this.displayProviderList();
      }
      else {
        this.loader.hide();
        this.addLocationError = true;
      }
    });
  }
  isZipLabelOutSide = false;
  isZipLabelVisible = true;
  private displayProviderList() {
    if (this.providerCount > 0 && $("#Isworkflow").val() == "1") {
      $("#btnProviderList").show();
      $("#btnProviderList").text("View Provider List (" + this.providerCount + ")");
      $("#btnNext").hide();
      $("#btnNextupper").hide()
      $("#btnbkupper").hide()

    }
    else {
      $("#btnProviderList").hide();
      if ($("#Isworkflow").val() == "1")
        $("#btnNext").show();
      $("#btnNextupper").show()
      $("#btnbkupper").show()
    }
  }

  moveZipLabelOut(searchType) {
    this.isZipLabelOutSide = true;
  }
  moveZipLabelIn() {
    if (this.model !== "") {

      this.isZipLabelVisible = true;

      this.isZipLabelOutSide = false;
    }
  }

  isProviderLabelOutSide = false;
  moveProviderLabelOut(searchType) {
    this.isProviderLabelOutSide = true;
  }
  moveProviderLabelIn() {
    this.isProviderLabelOutSide = false;
  }
  hideDropDown() {
    var dropDown = document.getElementsByClassName("dropdown-menu");
    if (dropDown != undefined && dropDown != null) {
      if (dropDown[0] != undefined && dropDown[0] != null) {

        dropDown[0].setAttribute("style", "display:none;");
      }

    }

  }

  showDropDown() {
    var dropDown = document.getElementsByClassName("dropdown-menu");
    if (dropDown != undefined && dropDown != null) {
      if (dropDown[0] != undefined && dropDown[0] != null) {

        dropDown[0].setAttribute("style", "display:block;");
      }

    }

  }

  formatMatches = (value: any) => /\d/.test(this.searchstring) ? value.Zip + "    " + value.City : value.City + "    " + value.Zip;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(term => /\d/.test(term) ? (term.trim() === '' || term.length < 3 ? this.setToFalse() : this.searching = true)
        : (term.trim() === '' || term.length < Settings.MinimumAutoPopulateCharacters ? this.setToFalse() : this.searching = true)),
      switchMap(term => /\d/.test(term) ? (term.length < Settings.MinimumAutoPopulateCharacters ? [] : this._service.search(term).pipe(tap(response => {
        if (response == null || response == "" || response == []) {
          this.searchFailed = true;
          this.displaySelectCarriers = false;
        } else {
          this.searchFailed = false;
          this.showDropDown();
        }
      }))) : term.length < Settings.MinimumAutoPopulateCharacters ? [] :
        this._service.search(term).pipe(
          tap(() => this.searchFailed = false),
          tap(() => this.searchstring = term),
          tap(response => {
            if (response == null || response == "" || response == []) {
              this.searchFailed = true;
              this.displaySelectCarriers = false;
            } else {
              this.searchFailed = false;
            }
          }),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))),
      tap(() => this.searching = false),

      merge(this.hideSearchingWhenUnsubscribed));

  formatMatchesForProvider = (value: any) => this.currentProvider.name == "Doctor" ? ((value.FirstName !== undefined ? value.FirstName : "") + " " + (value.LastName !== undefined ? value.LastName : "")) : value.FacilityName;
  searchProvider = (text$: Observable<string>) =>

    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(term => term.trim() === '' || term.length < Settings.MinimumAutoPopulateCharacters ? (this.searchingProvider = false, this.providerSearchMinLength = true, this.providerSearchFailed = false, this.gbpError = false, this.unsubscribeSearch())
        : (this.searchingProvider = true, this.providerSearchFailed = false, this.gbpError = false, this.providerSearchMinLength = false, this.unsubscribeSearch())),
      switchMap(term => term.length < Settings.MinimumAutoPopulateCharacters ? [] :
       
        this._service.searchProvider(term, this.model.Zip, this.carrierString, this.currentProvider.name).pipe(
          tap(() => { this.providerSearchFailed = false, this.showDropDown() }),
          tap(() => this.prviderSearchString = term),
          tap(response => { if (response == null || response == "" || response == []) { this.providerSearchFailed = true; this.providerSearchMinLength = false } }),
          catchError(() => {
            this.providerSearchFailed = true;
            this.providerSearchMinLength = false;
            return of([]);
          }))),
      tap(() => this.searchingProvider = false),
      tap(() => this.loader.hide()),
      merge(this.hideProviderSearchingWhenUnsubscribed));

  setToFalse() {
    this.searching = false;
    this.displaySelectCarriers = false;
    this.displaySelectProvider = false;
    this.isGBPVisible = false;
    // this.model = null;
    for (var i = 0; i < this.carriers.length; i++) {
      this.carriers[i].selected = false;
    }
    this.carrierString = '';
    this.selectedAll = false;
    this.providerSearchFailed = false;
    this.providerSearchMinLength = false;
    this.searchingProvider = false;
    this.gbpError = false;
    this.providerModel = null;
  }

  navigateToProviderList() {

    this._routes.navigate(['addProvider']);
  }

}


