import { ApplicationInsightsService } from './../../../service/application-insights.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { FileService } from '../../../service/file.service';
import { saveAs, FileSaver } from 'file-saver/FileSaver';
import { ZelisApiService } from '../../../service/zelisApi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-import-search',
  templateUrl: './import-search.component.html',
  styleUrls: ['./import-search.component.css'],
  providers: [NgbCarouselConfig],
})
export class ImportSearchComponent implements OnInit {
  count: number = 0;
  errors: Array<string> = [];
  dragAreaClass: string = 'dragarea';
  searchResult: any;
  providerlistUUid: string;
  masterCensusId: string;
  isResultVisible: boolean;
  addLocationError = false;
  providerCount: number = 0;
  removeLocationError = true;
  currentProviderlist: any;
  @Input() fileExt: string = 'xlsx, xls';
  @Input() maxFiles: number = 1;
  @Input() maxSize: number = 2; // 2MB
  @Input() providerListData: any;
  @Output() uploadStatus = new EventEmitter();
  @ViewChild('excelInput', { static: true })
  excelInput: any;
  constructor(
    private fileService: FileService,
    private loader: NgxSpinnerService,
    config: NgbCarouselConfig,
    private route: ActivatedRoute,
    private _routes: Router,
    private _zelisApiService: ZelisApiService,
    private _applicationInsightsService: ApplicationInsightsService,
  ) {
    config.interval = 0;
    this.masterCensusId = localStorage.getItem('masterCensusId');
    this.providerlistUUid = localStorage.getItem('providerListUUId');
  }

  ngOnInit() {
    this.isResultVisible = false;
    this.currentProviderlist = this.providerListData;
    console.log(this.currentProviderlist);
    console.log('child of child Data' + this.currentProviderlist);
    this.countCurrentProviders();
  }

  compareWithExisting() {
    this.searchResult[0].ExcelResponse.forEach((element) => {
      if (element.ExcelRowData.ErrorMessages.length === 0) {
        if (
          element.SearchResult.Providers !== undefined &&
          element.SearchResult.Providers.length !== 0
        ) {
          element.SearchResult.Providers.forEach((provider) => {
            provider.Locations.forEach((location) => {
              location.showAdd = true;
            });
            provider.showAdd = true;
            if (this.currentProviderlist != undefined) {
              this.currentProviderlist.forEach((currentProvider) => {
                if (provider.ProviderId == currentProvider.ProviderId) {
                  if (
                    provider.Locations !== undefined &&
                    provider.Locations.length !== 0
                  ) {
                    provider.Locations.forEach((location) => {
                      location.showAdd = true;
                      if (
                        currentProvider.ProviderLocations !== undefined &&
                        currentProvider.ProviderLocations.length !== 0
                      ) {
                        currentProvider.ProviderLocations.forEach(
                          (currentLocation) => {
                            if (
                              location.ProviderLocationId ==
                              currentLocation.ProviderLocationId
                            ) {
                              location.showAdd = false;
                            }
                          },
                        );
                      }
                    });
                  }
                }
              });
            } else {
              return false;
            }
          });
        }
      }
    });
    return false;
  }
  removeProvider(provider, location) {
    this.removeLocationError = false;
    this.loader.show();

    let deleteProvider = {
      MasterCensusGroupId: Number(this.masterCensusId),
      ProviderListUUID: this.providerlistUUid,
      ProviderLocationId: Number(location.ProviderLocationId),
      NetworkIds: location.Networkids,
    };
    this._zelisApiService
      .deleteProviderbyId(deleteProvider)
      .subscribe((data) => {
        if (data) {
          location.showAdd = true;
          this.loader.hide();
          this.removeLocationError = false;
          this.providerCount = this.providerCount - 1;

          // US#381070 - Make sure ProviderCount is NEVER less than Zero
          if (this.providerCount < 0) {
            this.providerCount = 0;
          }
          this._zelisApiService.counterChanged(this.providerCount);
          this.displayProviderList();
        } else {
          this.removeLocationError = true;
          this.loader.hide();
        }
      });
  }
  addProvider(provider, location) {
    this.loader.show();

    let addProvider = {
      MasterCensusGroupId: Number(this.masterCensusId),
      ProviderListUUID: this.providerlistUUid,
      ProviderLocationId: Number(location.ProviderLocationId),
      NetworkIds: location.Networkids,
      SelectedCarrierIds: location.CarrierGroupNames.map(
        (o) => o.CarrierId,
      ).join(','),
      ProviderId: Number(provider.ProviderId),
    };
    this._zelisApiService
      .addProviderbyId(addProvider)
      .subscribe((data: any) => {
        if (data !== '') {
          location.showAdd = false;
          this.addLocationError = false;
          this.providerlistUUid = data;
          localStorage.setItem('providerListUUId', data);
          this.loader.hide();
          this.providerCount = this.providerCount + 1;
          this._zelisApiService.counterChanged(this.providerCount);
          this.displayProviderList();
        } else {
          this.loader.hide();
          this.addLocationError = true;
        }
      });
  }
  private displayProviderList() {
    if (this.providerCount > 0 && $('#Isworkflow').val() == '1') {
      $('#btnProviderList').show();
      $('#btnProviderList').text(
        'View Provider List (' + this.providerCount + ')',
      );
      $('#btnNext').hide();
      $('#btnNextupper').hide();
      $('#btnbkupper').hide();
    } else {
      $('#btnProviderList').hide();
      if ($('#Isworkflow').val() == '1') $('#btnNext').show();
      $('#btnNextupper').show();
      $('#btnbkupper').show();
    }
  }
  countCurrentProviders() {
    if (this.currentProviderlist != undefined) {
      this.currentProviderlist.forEach((currentProvider) => {
        if (
          currentProvider.ProviderLocations !== undefined &&
          currentProvider.ProviderLocations.length !== 0
        ) {
          currentProvider.ProviderLocations.forEach((currentLocation) => {
            this.providerCount = this.providerCount + 1;
          });
        }
      });
    }
  }
  onFileChange(event) {
    event.preventDefault();
    event.stopPropagation();
    let files = event.target.files;
    this.saveFiles(files);
  }
  @HostListener('dragover', ['$event']) onDragOver(event) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    var files = event.dataTransfer.files;
    this.saveFiles(files);
  }
  download() {
    this.loader.show();

    this.fileService.download().subscribe((data) => {
      this.downloadFile(data.body);
      this.loader.hide();
    }),
      (error) => console.log('Error downloading the file.'),
      () => console.info('OK');
  }
  downloadFile(data: any) {
    var fileName = 'Import Template.xlsx';
    var blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    saveAs(blob, fileName);
  }
  saveFiles(files) {
    this.errors = []; // Clear error
    // Validate file size and allowed extensions
    if (files.length > 0 && !this.isValidFiles(files)) {
      this.uploadStatus.emit(false);
      this.searchResult = [];
      this.loader.hide();
      return;
    }
    if (files.length > 0) {
      let formData: FormData = new FormData();
      for (var j = 0; j < files.length; j++) {
        formData.append('file', files[j], files[j].name);
      }
      var parameters = {};
      this.loader.show();
      this.fileService.upload(formData).subscribe(
        (success) => {
          this.uploadStatus.emit(true);
          console.log(success);
          this.isResultVisible = true;
          this.searchResult = success.body;
          this.compareWithExisting();
          this.loader.hide();
        },
        (error) => {
          this.uploadStatus.emit(true);
          this.errors.push(error.ExceptionMessage);
          this.loader.hide();
        },
      );
    }
    this.excelInput.nativeElement.value = '';
  }

  private isValidFiles(files) {
    // Check Number of files
    if (files.length > this.maxFiles) {
      this.errors.push(
        'Error: At a time you can upload only ' + this.maxFiles + ' files',
      );
      return;
    }
    this.isValidFileExtension(files);
    return this.errors.length === 0;
  }

  private isValidFileExtension(files) {
    // Make array of file extensions
    var extensions = this.fileExt.split(',').map(function (x) {
      return x.toLocaleUpperCase().replace(/^\s+|\s+$/g, '');
    });

    for (var i = 0; i < files.length; i++) {
      // Get file extension
      var ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;

      // Check the extension exists
      var exists = extensions.indexOf(ext) > -1;

      if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function (obj, start) {
          for (var i = start || 0, j = this.length; i < j; i++) {
            if (this[i] === obj) {
              return i;
            }
          }
          return -1;
        };
      }

      if (!exists) {
        this.errors.push('Error (Extension): ' + files[i].name);
      }

      // Check file size
      this.isValidFileSize(files[i]);
    }
  }

  private isValidFileSize(file) {
    var fileSizeinMB = file.size / (1024 * 1000);
    var size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (size > this.maxSize)
      this.errors.push(
        'Error (File Size): ' +
          file.name +
          ': exceed file size limit of ' +
          this.maxSize +
          'MB ( ' +
          size +
          'MB )',
      );
  }

  navigateToProviderList() {
    this._routes.navigate(['addProvider']);
  }

  onSlideClicked() {
    this._applicationInsightsService.trackEvent(
      'ProviderSearch:Import-Provider-Navigation-Slide::Click',
    );
  }

  onNextProviderClicked() {
    this._applicationInsightsService.trackEvent(
      'ProviderSearch:Import-Provider-Navigation-Next::Click',
    );
  }
}
