<div id="providerlist-container" [@fadeInAnimation] class="provider-search-container">

  <div class="wrapper">

    <section id="disclaimer">

      <div class="row">
        <div class="columns">
          <h2 class="center-aligned">How do you want to build this group's provider list?</h2>
        </div>
      </div>

      <div class="row">
        <div class="columns">

          <div class="row">
            <div class="small-24 medium-12 columns button-manual text-center" [ngClass]="{'active':isdisplayManual}"
              data-qaauto="btnmanualentry">
              <a class="colpad" (click)="manualEntry()">
                <h2>Manual Entry</h2>
                <p>
                  Use this method for entering and selecting providers one-at-a-time.
                </p>
              </a>
            </div>
            <div class="small-24 medium-12 columns button-import text-center" [ngClass]="{'active':isimportExcel}">
              <a (click)="importExcel()" class="colpad">
                <h2>Import from Excel</h2>
                <p>
                  If you have the name and city or ZIP codes of the providers you need, use our Excel template to
                  quickly import your provider
                  list.
                </p>
              </a>
            </div>
          </div>

        </div>
      </div>

    </section>

  </div>

</div>

<div [@fadeInAnimation] *ngIf="isdisplayManual">
  <app-manual-entry [providerListForManualEntry]="providerList"></app-manual-entry>
</div>
<div [@fadeInAnimation] *ngIf="isimportExcel">
  <app-import [providerList]="providerList"></app-import>
</div>
<div style="padding: 100px !important;">

</div>