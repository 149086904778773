 
 
 

  <div id="providerlist-container" class="provider-search-container">

    <div class="wrapper">

    <!-- Disclaimer Should be here -->
<section id="disclaimer">

        <div class="row">
          <div class="columns">
            <h1>Terms and Conditions</h1>
          </div>
        </div>

        <div class="row">
          <div class="columns">
            <p>
              The information contained herein, including without limitation, provider search results, was collected by Word & Brown from
              sources considered accurate and reliable at the time of collection. However, we have not verified nor can we
              guarantee the accuracy, timeliness, or completeness of such information. Users of this information further
              acknowledge and agree that the information contained below is provided merely as a guide by Word & Brown. It
              is, therefore, recommended that the User verify the status of your coverage and the below information with
              your doctor's office and the carrier before relying upon and basing any decisions on the information provided.
              Word & Brown disclaims any and all liability regarding the accuracy and reliability of said information. By
              electing Accept, you consent to the above.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="columns">
            <div class="buttons">
              <a (click)="navigateToAddProvider()" class="button large grey">Cancel</a>
              <a (click)="navigateToProviderSearch()" class="button large">Accept</a>
            </div>
          </div>
        </div>

      </section>
    </div>

  </div>
 
 
 
 
  