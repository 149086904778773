import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProviderlistComponent } from './providerlist/providerlist.component';
import { AuthService } from './shared/auth.service';
import { LocalStorage } from './shared/constants';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { ZelisApiService } from './service/zelisApi.service';
import { AppSettings } from './shared/appSettings';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorHandler, HandleError } from './service/httpErrorHandler.service';
import { fadeInAnimation } from './animations/fade-in.animation';
import { routerTransition } from './animations/router.animation';
import { slideInOutAnimation } from './animations/slide-in-out.animation';

import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    animateChild
} from '@angular/animations';
declare var $: any
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [ZelisApiService],
    // make fade in animation available to this component
    // animations: [routerTransition],
    animations: [
        trigger('animRoutes', [
            transition('* <=> *', [
                group([
                    query(
                        ':enter',
                        [
                            style({
                                opacity: 0,
                                transform: 'translateY(9rem) rotate(-10deg)'
                            }),
                            animate(
                                '1s cubic-bezier(0, 1.8, 1, 1.8)',
                                style({ opacity: 1, transform: 'translateY(0) rotate(0)' })
                            ),
                            animateChild()
                        ],
                        { optional: true }
                    ),
                    query(
                        ':leave',
                        [animate('1s', style({ opacity: 0 })), animateChild()],
                        { optional: true }
                    )
                ])
            ])
        ])
    ]
})
export class AppComponent implements OnInit {
    providerCount: number = 0;
    isGenerateReportVisible: boolean;
    isViewProviderListVisible: boolean;
    isdisplayReport: boolean;
    isAuthenticated: boolean;
    ngOnInit() {
        this.isGenerateReportVisible = false;
        this.isViewProviderListVisible = false;
        this.isdisplayReport = false;
        this.isAuthenticated = false;
        $(document).foundation();
        window.onscroll = function () {
            if (window.pageYOffset >= 120) {
                var elem = document.getElementById("fixed-row");
                elem.style.top = "0";
                elem.setAttribute("style", "top:0;box-shadow: rgb(153, 153, 153) 0px 10px 12px -6px;-webkit-box-shadow:rgb(153, 153, 153) 0px 10px 12px -6px;-moz-box-shadow:rgb(153, 153, 153) 0px 10px 12px -6px;");
            }
            else {
                var elem = document.getElementById("fixed-row");
                elem.style.top = "auto";
                elem.setAttribute("style", "box-shadow: none;-moz-box-shadow:none;-webkit-box-shadow:none;top:auto;");
            }

        };
        this._zelisApi.newProviderCount.subscribe(count => { this.providerCount = count, console.log("PrviderCountChanged" + count) });
        this._zelisApi.generateReportToggled.subscribe(flag => {
            let isreportdisplay = localStorage.getItem("isGenerateReport");
            if (isreportdisplay == "true") {
                this.isGenerateReportVisible = false;
            } else { this.isGenerateReportVisible = flag }

            console.log("Report Visibility Changed")

        });
        this._zelisApi.viewProviderListToggled.subscribe(flag => { this.isViewProviderListVisible = flag, console.log("Report Visibility Changed") });
    }
    getPage(outlet) {
        return outlet.activatedRouteData.state;
    }
    title = 'Provider-Search';
    private handleError: HandleError;
    providerListUUId: string = "";
    masterCensusId: string = ""

    clientId: string;
    loggedInName: string;
    constructor(
        private oauthService: OAuthService,
        private loader: NgxSpinnerService, private _authService: AuthService, private _routes: Router,
        private _httpClient: HttpClient, _httpErrorHandler: HttpErrorHandler, private _zelisApi: ZelisApiService) {

        if (window.location.href.indexOf("isEmbedded") > -1) {
            localStorage.setItem(LocalStorage.IsEmbeddedApp, "true");
        }
        if (localStorage.getItem(LocalStorage.IsEmbeddedApp) != "true") {
            if (window.location.hash) {
                this._authService.ParseSaveAccessToken();
            }
        }
        if (localStorage.getItem(LocalStorage.IsEmbeddedApp) == "true") {
            this.savedata();
        }
        this.printUserName();

    }
    private savedata() {
        if (window.location.hash && window.location.href.indexOf("masterCensusId") > -1
            && window.location.href.indexOf("providerListUUId") > -1
            && window.location.href.indexOf("isdisclaimerVisible") > -1) {
            var hash = window.location.hash.substr(1);
            var result: any = hash.split('&').reduce((result: any, item: any) => {
                var parts = item.split('=');
                result[parts[0]] = parts[1];
                return result;
            }, {});
            if (result.masterCensusId != undefined && result.masterCensusId != "") {
                localStorage.setItem("masterCensusId", result.masterCensusId);
            }
            if (result.providerListUUId == undefined || result.providerListUUId == "" || result.providerListUUId == null) {

                localStorage.setItem("providerListUUId", '');
            }
            else {
                localStorage.setItem("providerListUUId", result.providerListUUId);
            }
            if (result.isdisclaimerVisible != undefined && result.isdisclaimerVisible != "") {
                localStorage.setItem("isdisclaimerVisible", result.isdisclaimerVisible);
            }
            if (result.isGenerateReport != undefined && result.isGenerateReport != "") {
                localStorage.setItem("isGenerateReport", result.isGenerateReport);
            }
            if (result.IsBlueShieldProviderIncluded != undefined && result.IsBlueShieldProviderIncluded != "") {
                localStorage.setItem("IsBlueShieldProviderIncluded", result.IsBlueShieldProviderIncluded);
            }
        }
        this.navigationToPage()

    }
    navigationToPage() {
        this._routes.navigate(['addProvider']);
    }
    navigateToProviderList() {
        this.isViewProviderListVisible = false;
        this._routes.navigate(['addProvider']);
    }
    printUserName() {
        if (localStorage.getItem(LocalStorage.IsEmbeddedApp) != "true" && this._authService.isLoggedIn()) {
            this.loggedInName = localStorage.getItem(LocalStorage.Name);
            return true;
        }
        this.loggedInName = '';
        return false;
    }

    logout() {
        this._authService.logOff();
    }
}