<div class="row align-center">
	<div class="medium-12 columns">
		<div class="control align-center">
			<!--<input type="text" class="placeholder" placeholder="City or ZipCode">-->
			<div class="form-group align-center text-center">
				<h2 for="typeahead-http" class="text-center">Provider Location</h2>
				<input id="typeahead-http" #providerSearch autofocus type="text" class="form-control ps-input" [class.is-invalid]="searchFailed" [(ngModel)]="model"
				 (selectItem)="displayCarriers(model)" [ngbTypeahead]="search" placeholder="Enter City or Zip" [resultFormatter]="formatMatches"
				 [inputFormatter]="formatMatches" data-qaauto="ddlproviderlocation"  />
				<!-- <span class="floating-label" *ngIf="isZipLabelVisible" [ngClass]="{'FloatOut':isZipLabelOutSide}">Provider Location</span> -->
				<div *ngIf="searching">searching...</div>
				<div class="invalid-feedback" *ngIf="searchFailed">City or ZIP Code not found</div>
			</div>

		</div>

	</div>

</div>
<div *ngIf="displaySelectCarriers">
	<!--<app-import-provider></app-import-provider>-->

	<div class="row selectCarrierRow">
		<div class="columns">
			<h2 id="selectCarrier"  class="center-aligned">Select Carrier</h2>
			<p class="center-aligned">Select the carriers you wish to search; contract status and enrollment information will be provided for all their available
				networks.
			</p>
		</div>
	</div>

	<div class="row align-center">
		<div class="medium-12 columns">
			<ul class="select-set">
				<li class="all" [ngClass]="{'selected': selectedAll === true }">
					<input type="checkbox" id="checkAll" [(ngModel)]="selectedAll" (change)="selectAll();">
					<label for="checkAll">
						<h3> All</h3>
					</label>
				</li>
				<li *ngFor="let c of carriers" [ngClass]="{'selected': c.selected === true }">
					<div class="title">
						<input type="checkbox" id="{{c.name}}" [(ngModel)]="c.selected" (change)="checkIfAllSelected();">
						<label for="{{c.name}}">
							<h3 *ngIf="c.id==7">California<i>Choice</i>
								</h3>
								<h3 *ngIf="c.id!=7">{{c.name}}</h3>
						</label>
					</div>
				</li>
			</ul>
		</div> 
 
	</div>




</div>
<div [hidden]="!displaySelectProvider">
	<div class="row align-center providerTitleRow">
		<div class="columns">
			<h2 id="providerSection">Provider</h2>
		</div>
	</div>

	<div class="row">
		<div class="columns">
			<div class="buttons toggle align-center text-center">
				<a *ngFor="let p of providerTypes" class="button large" [ngClass]="{'grey': p.selected === false }" (click)="providerTypeClick(p)">{{p.name}}</a>
				<!--<a href="" class="button large grey">Facility</a>-->

				<!--<div class="row align-center">
		<div class="medium-12 columns">
			<ul class="select-set">
			
				<li *ngFor="let p of providerTypes" [ngClass]="{'selected': p.selected === true }">
					<div class="title">
						<input type="radio" name="providerRadio" [(ngModel)]="p.selected" (change)="onSelectionChange(p)"   [checked]="p.selected" >
						<label for="{{c.name}}"><h3> {{p.name}}</h3></label>
					</div>
				</li>


			</ul>
		</div>

	</div>-->
			</div>
		</div>
	</div>

	<div class="row align-center">
		<div class="medium-12 columns">
			<div class="control">
				<div class="form-group text-center" *ngFor="let p of providerTypes">
					<span [hidden]="!p.selected">
						<input id="typeahead-http1" type="text" class="form-control placeholder ps-input" [class.is-invalid]="providerSearchFailed" [(ngModel)]="providerModel"
						 typeahead-select-on-blur="false" (keyup.enter)="getGBPData($event, 1)" (selectItem)="getGBPData($event, 0)" [ngbTypeahead]="searchProvider"
						 placeholder="{{p.placeHolder}}" [resultFormatter]="formatMatchesForProvider" [inputFormatter]="formatMatchesForProvider" data-qaauto="ddldoctor"
						/>
						<div *ngIf="searchingProvider">searching providers...</div>
						<div class="invalid-feedback" *ngIf="providerSearchFailed">No match was found. Please try again.</div>
						<div class="invalid-feedback" *ngIf="providerSearchMinLength">Please enter minimum of three characters.</div>
						<div class="invalid-feedback" *ngIf="gbpError">An error has occured while fetching Groups by Providers.</div>
					</span>
				</div>

			</div>

		</div>

	</div>
</div>
<Span *ngIf="isGBPVisible">
	<section id="verifing-entries" class="GBP-Result-Section">
		<div class="row pane" *ngFor="let p of gbpResultData.Providers">
			<div class="columns">

				<div class="row">
					<div class="small-24 medium-14 large-24 columns">
						<div class="colpad" *ngIf="currentProvider.name === 'Doctor'">
							<h2>{{p.LastName}} {{p.FirstName}}</h2>
						</div>
						<div class="colpad" *ngIf="currentProvider.name !== 'Doctor'">
							<h2>{{p.FacilityName}}</h2>
						</div>
					</div>

				</div>

				<div class="row provider-location" *ngFor="let l of p.Locations">
					<div class="small-24 medium-9 large-10 columns">
						<div class="row">
							<div class="small-24 columns">
								<div class="colpad">
									<h3>{{l.Address}}</h3>
									<p>{{l.City}},{{l.State}} {{l.Zip}}</p>
								</div>
							</div>
							<div class="small-24 columns">
								<div class="colpad">
									<p class="text-blue">
										<i>{{l.Specialities || concatStringArray}}</i>
									</p>

									<!-- <span class="accepting" *ngIf="l.AcceptingNewPatients">Accepting</span>
							  <span class="accepting not-accepting" *ngIf="!l.AcceptingNewPatients">Not Accepting</span> -->
								</div>
							</div>
						</div>
					</div>
					<div class="small-24 medium-9 large-10 columns">
						<div class="colpad">
							<div *ngFor="let c of l.CarrierGroupNames">
								<h3 *ngIf="c.CarrierId===7">California<i style="font-weight: 400 !important;">Choice</i>
								</h3>
								<h3 *ngIf="c.CarrierId!==7">{{c.CarrierName}}</h3>
								<p>{{c.GroupNames || concatStringArray}}</p>
							</div>

						</div>
					</div>
					<div class="small-24 medium-6 large-4 columns">
						<div class="colpad">
							<a class="button expanded" *ngIf="l.showAdd" (click)="addProvider(p,l)">Add</a>
							<a class="button expanded grey" *ngIf="!l.showAdd" (click)="removeProvider(p,l)">Remove</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</Span>
