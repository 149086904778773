import { Component, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from '../shared/constants';
import { LocalStorage } from '../shared/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppSettings } from '../shared/appSettings';
import { ApplicationInsightsService } from '../service/application-insights.service';
import { filter } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public IsAuthorized: boolean;
  jwtHelper: JwtHelperService = new JwtHelperService();
  tittle: 'app';
  constructor(
    private oauthService: OAuthService,
    private _applicationInsightsService: ApplicationInsightsService,
  ) {
    this.ClientAuthenticationWithSecureAccess();
    this.IsAuthorized = false;
  }
  AuthenticateClientAndGetToken() {
    this.clearLocalStorage();
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.initImplicitFlow();    
  }

  ParseSaveAccessToken() {
    var hash = window.location.hash.substr(1);
    var result: any = hash.split('&').reduce((result: any, item: any) => {
      var parts = item.split('=');
      result[parts[0]] = parts[1];
      return result;
    }, {});

    setTimeout(() => {
      var href = window.location.href,
        newUrl = href.substring(0, href.indexOf('#'));
      window.history.pushState('', 'newpagetitle', newUrl);
    }, 300);

    let nonce = sessionStorage.getItem('nonce');

    if (!result.error && nonce == result.state) {
      // decode id token to get name & email of the logged in user
      this.addLocalStorage(result);
      this.IsAuthorized = true;
    } else {
      this.AuthenticateClientAndGetToken();
    }
  }

  clearLocalStorage() {
    localStorage.removeItem(LocalStorage.Access_Token);
    localStorage.removeItem(LocalStorage.Id_Token);
    localStorage.removeItem(LocalStorage.Token_Type);
    localStorage.removeItem(LocalStorage.Email_Id);
    localStorage.removeItem(LocalStorage.Name);
    localStorage.removeItem(LocalStorage.UserName);
    localStorage.removeItem(LocalStorage.Role);
    this.IsAuthorized = false;
    this._applicationInsightsService.clearUserId();
  }

  addLocalStorage(result) {
    let token_decode = this.jwtHelper.decodeToken(result.id_token);
    localStorage.setItem(LocalStorage.Email_Id, token_decode.email);
    localStorage.setItem(
      LocalStorage.Name,
      token_decode[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ],
    );
    localStorage.setItem(
      LocalStorage.UserName,
      token_decode.preferred_username,
    );
    localStorage.setItem(LocalStorage.Access_Token, result.access_token);
    localStorage.setItem(LocalStorage.Id_Token, result.id_token);
    localStorage.setItem(LocalStorage.Token_Type, result.token_type);
    localStorage.setItem(LocalStorage.Exp_In, result.expires_in);
    localStorage.setItem(
      LocalStorage.Exp_Date,
      new Date().setSeconds(result.expires_in).toString(),
    );
    localStorage.setItem(LocalStorage.Role, token_decode.role);
    this._applicationInsightsService.turnOn();
    this._applicationInsightsService.setUserId(
      this.email,
      this.fullName,
      this.userRole,
    );
  }

  isLoggedIn() {
    if (
      localStorage.getItem(LocalStorage.Access_Token) != null &&
      localStorage.getItem(LocalStorage.Access_Token) != '' &&
      localStorage.getItem(LocalStorage.Access_Token) != undefined &&
      new Date(parseInt(localStorage.getItem(LocalStorage.Exp_Date))) >
        new Date()
    ) {
      return (this.IsAuthorized = true);
    } else {
      return (this.IsAuthorized = false);
    }
  }

  logOff() {
    this._applicationInsightsService.clearUserId();
    var authorizationUrl =
      AppSettings.getEnvironmentVariable('authEndPoint') + 'connect/endsession';
    var post_logout_redirect_uri =
      window.location.protocol + '//' + window.location.host;
    var url =
      authorizationUrl +
      '?' +
      'id_token_hint=' +
      encodeURI(localStorage.getItem(LocalStorage.Id_Token)) +
      '&' +
      'post_logout_redirect_url=' +
      encodeURI(post_logout_redirect_uri);

    this.clearLocalStorage();

    window.location.href = url;
  }

  get fullName() {
    return localStorage.getItem(LocalStorage.Name);
  }

  get userRole(): any {
    return localStorage.getItem(LocalStorage.Role);
  }

  get userId() {
    return localStorage.getItem(LocalStorage.UserName);
  }

  get email() {
    return localStorage.getItem(LocalStorage.Email_Id);
  }
  private ClientAuthenticationWithSecureAccess() {
    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type)),
      )
      .subscribe((e) => {
        console.log('Your session has been terminated!');
        sessionStorage.clear();
        window.location.href = AppSettings.getEnvironmentVariable(
          'SingleSignoutRedirect',
        );
      });
  }
}
