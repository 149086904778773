import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, RouterModule, Routes, Router } from "@angular/router";
import { ZelisApiService } from '../service/zelisApi.service';
import { fadeInAnimation } from '../animations/fade-in.animation';
import { ApplicationInsightsService } from './../service/application-insights.service';

declare var $: any;

@Component({
  selector: 'app-provider-search',
  templateUrl: './provider-search.component.html',
  styleUrls: ['./provider-search.component.css'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],

  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class ProviderSearchComponent implements OnInit {

  masterCensusGroupId: string = "";
  isdisplayManual: boolean;
  isimportExcel: boolean;
  providerlistUUid: string;
  masterCensusId: string;
  providerCount: number = 0;
  public providerList: any;
  constructor(
    private loader: NgxSpinnerService,
    private route: ActivatedRoute, private _routes: Router, private _zelisApi: ZelisApiService, private _applicationInsightsService: ApplicationInsightsService) {
    this.masterCensusId = localStorage.getItem("masterCensusId");
    this.providerlistUUid = localStorage.getItem("providerListUUId");
  }
  manualEntry() {
    this._applicationInsightsService.trackEvent('ProviderSearch:Provider-List-Manual-Entry::Click');
    this.isdisplayManual = true;
    this.isimportExcel = false;
  }
  importExcel() {
    this._applicationInsightsService.trackEvent('ProviderSearch:Provider-List-Import-Excel::Click');
    this.isimportExcel = true;
    this.isdisplayManual = false;
  }
  ngOnInit() {
    this.isdisplayManual = false;
    this.isimportExcel = false;
    if (this.providerlistUUid != '' && this.providerlistUUid != null && this.providerlistUUid != undefined) {
      this.loader.show();
      this._zelisApi.getProviderList(this.providerlistUUid).subscribe(response => {
        this.loader.hide();
        this.providerList = response;
        this.countProviders();
        this.displayProviderList();
      });
    }
    this._zelisApi.toggleGenerateReport(false);
    let isProviderListdisplay = localStorage.getItem("isGenerateReport");
    if (isProviderListdisplay == "true") {
      this._zelisApi.toggleViewProviderList(false);
    } else { this._zelisApi.toggleViewProviderList(true); }
    this._zelisApi.newProviderCount.subscribe(count => { this.providerCount = count });

  }

  private displayProviderList() {
    if (this.providerCount > 0 && $("#Isworkflow").val() == "1") {
      $("#btnProviderList").show();
      $("#btnProviderList").text("View Provider List (" + this.providerCount + ")");
      $("#btnNext").hide();
      $("#btnNextupper").hide()
      $("#btnbkupper").hide()

    }
    else {
      $("#btnProviderList").hide();
      if ($("#Isworkflow").val() == "1")
        $("#btnNext").show();
      $("#btnNextupper").show()
      $("#btnbkupper").show()
    }


  }


  countProviders() {
    if (this.providerList != undefined) {
      this.providerList.forEach(currentProvider => {
        if (currentProvider.ProviderLocations !== undefined && currentProvider.ProviderLocations.length !== 0) {
          currentProvider.ProviderLocations.forEach(currentLocation => {
            this.counter = this.counter + 1;

          })
        }

      });
    }
    this._zelisApi.counterChanged(this.counter);
  }
  navigateToProviderList() {

    this._routes.navigate(['addProvider']);
  }
  counter: number = 0;
  countChangedHandler(count: number) {
    this.counter = count;
  }

}
