<a *ngIf="enableExportButton == false" class="button madium black export-button disabled">Generate Report</a>
<a *ngIf="enableExportButton == true" class="button madium black export-button" data-toggle="exampleModal6"
  (click)="initializeExport()">Generate Report</a>
<div class="full reveal provider-search-container" id="exampleModal6" data-reveal>
  <section id="disclaimer" class="provider-search-container">
    <div class="row">
      <div class="columns">
        <h2 class="center-aligned">Select an option to export the provider list</h2>
      </div>
    </div>


    <div class="row">
      <div class="columns">

        <div class="row">
          <div class="small-24 columns center-aligned">
            <a class="button large" id="DownloadPdf" (click)="downloadPDF()">
              Download PDF
            </a>
            <a class="button large" id="EmailPdf" (click)="enableEmailSection()">
              Email PDF
            </a>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="showEmailSection">
      <div class="row align-center">
        <div class="medium-12 columns">
          <div class="control">
            <input type="text" [(ngModel)]="emailAddresses" [pattern]="multipleEmailPattern" type="email"
              #emails="ngModel" class="placeholder ps-input" placeholder="Email address" required>
          </div>
          <p class="center-aligned">Use ; to enter multiple email addresses</p>
          <div *ngIf="showEmailMessage" class="center-aligned">
            <span *ngIf="emailError" class="center-aligned"><strong>Sorry, we are unable to deliver your email at this
                time.</strong> <br />
              Our team has been notified about the issue. While they resolve this issue do you want try again or <a
                (click)="downloadPDF()">download PDF</a>?
            </span>
            <span *ngIf="!emailError" class="center-aligned">Email Sent Successfully !</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="columns text-center">
          <div class="buttons" *ngIf="emails.errors">
            <a class="button large disabled" style="color:black !important">Send</a>
          </div>
          <div class="buttons" *ngIf="!emails.errors">
            <a class="button large" (click)="emailPDF(emailAddresses)">Send</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <button class="close-button button medium black" data-close aria-label="Close reveal" type="button">
    Back
  </button>
</div>